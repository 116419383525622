<template>
  <v-app-bar app class="appbar" clipped-left :class="classCordova">
    <v-row class="mt-2" no-gutters :align="'center'">
      <v-col
        cols="1"
        class="justify-left align-center"
        v-if="$routerHistory.hasPrevious()"
      >
        <!-- <router-link
          class="router-back"
          active-class="noop"
          :to="{ path: $routerHistory.previous().path }"
        >
          <v-btn class="no-border" aria-label="Torna Indietro" icon>
            <v-icon class="default--text back-arrow">mdi-chevron-left</v-icon>
          </v-btn>
        </router-link> -->
        <v-btn
          v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
          @click.prevent.stop="historyBack()"
          class="back-button no-border"
          aria-label="Torna Indietro"
          icon
        >
          <v-icon medium>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="3"
        sm="2"
        :offset-sm="$routerHistory.hasPrevious() ? 0 : 1"
        md="2"
        lg="2"
        xl="1"
        class="justify-center align-center logo-mobile"
      >
        <!-- logo mobile -->
        <router-link to="/" class="pr-0 pl-1 d-flex d-md-none">
          <v-img
            src="/logo.png"
            alt="Logo Iperal Spesa Online"
            max-height="38px"
            max-width="88px"
            contain
            eager
          />
        </router-link>
      </v-col>
      <v-col
        :cols="$routerHistory.hasPrevious() ? 7 : 8"
        :sm="8"
        md="6"
        class="d-flex flex-row justify-center align-center px-md-2 "
        style="flex-wrap: nowrap;"
      >
        <div
          style="min-width: 100px; max-width: 100%;"
          class="flex-grow-1 flex-shrink-0 pl-1"
        >
          <SearchInput />
        </div>
      </v-col>
      <v-col cols="1" md="3" class="d-flex justify-end">
        <!-- <CartInfoList v-if="isAuthenticated" class="d-none d-md-block" /> -->
        <v-btn
          icon
          large
          class="no-border"
          @click.stop="drawerLinks = !drawerLinks"
          style="right: -5px"
        >
          <v-icon large>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
      class="progress-bar"
    ></v-progress-linear>
  </v-app-bar>
</template>
<script>
//TODO rivedere completamente la gestione dei breakpoint in modo che non si scassino su telefono....
import login from "~/mixins/login";

import SearchInput from "@/components/navigation/SearchInput";
// import CartInfoList from "@/components/cart/CartInfoList";
import { forEachCartItem } from "~/service/ebsn";
import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  mixins: [login],
  components: { SearchInput },
  data() {
    return {
      isMobile: false
      // key: 1
    };
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),

    async doLogout() {
      let res = await this.logout();
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: "Home",
            path: "/"
          });
        }, 200);
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    // onResize() {
    //   this.key = this.key + 1;
    // },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
      // loading: state => state.loading
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      let total = 0;
      forEachCartItem(this.cart, function(item) {
        total += item.quantity > 0 ? item.quantity : 1;
      });
      return total;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  }
};
</script>
